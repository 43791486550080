<template>
  <aTable
    class="travel-table generate-invoices-table"
    :columns="columns"
    :data-source="frontendFilters(newContractList(contractList))"
    :loading="loading"
    :expandIconAsCell="false"
    :expandIconColumnIndex="0"
    :pagination="{ pageSize: 40, showSizeChanger: true }"
    :scroll="{ x: 800 }"
  >
    <div slot="sale_id" slot-scope="record">
      <aTooltip>
        <template #title> Contrato: {{ record.id }} </template>
        {{ record.sale_id != 0 ? record.sale_id : record.id }}
      </aTooltip>
    </div>

    <div slot="product_type" slot-scope="record">
      <aTooltip :title="record.product">
        <img
          class="grayscale"
          v-if="record.product_type === 'flight'"
          src="@/assets/images/dashboard/contracts/flight-ico.png"
          alt="img"
          width="20"
          style="opacity: 0.7"
        />

        <img
          class="grayscale"
          v-if="record.product_type === 'hotel'"
          src="@/assets/images/dashboard/contracts/hotel-ico.png"
          alt="img"
          width="20"
          style="opacity: 0.5"
        />

        <img
          class="grayscale"
          v-if="record.product_type === 'service'"
          src="@/assets/images/dashboard/contracts/services-ico.png"
          alt="img"
          width="19"
          style="opacity: 0.6"
        />
      </aTooltip>
    </div>

    <div slot="product" slot-scope="record">
      <div class="dotted-phrase upper">
        <aTooltip :title="record.product">
          {{ record.product }}
        </aTooltip>
      </div>
    </div>

    <div slot="traveller_name" slot-scope="record">
      <div class="dotted-phrase upper">
        <a-tooltip :title="record.traveller_name">
          {{ record.traveller_name }}
        </a-tooltip>
      </div>
    </div>

    <div slot="incomings" slot-scope="record">
      {{ record.incomings | formatListPriceNumAndStr }}
    </div>

    <div slot="to_pay" slot-scope="record">
      {{ record.to_pay | formatListPriceNumAndStr }}
    </div>

    <div slot="balance" slot-scope="record">
      {{ record.balance | formatListPriceNumAndStr }}
    </div>

    <div slot="ticket_value" slot-scope="record">
      {{ record.ticket_value | formatListPriceNumAndStr }}
    </div>

    <div slot="ticket_tax_value" slot-scope="record">
      {{ record.ticket_tax_value | formatListPriceNumAndStr }}
    </div>

    <div slot="extra_fee_value" slot-scope="record">
      {{ record.extra_fee_value | formatListPriceNumAndStr }}
    </div>

    <div slot="expandedRowRender" slot-scope="record">
      <div style="padding: 20px 0 10px">
        <aRow>
          <aCol :span="6">
            <div class="cblack fw-600 pb-5 f10">
              {{ record.product_type === "flight" ? "Viajante" : "Viajantes" }}
            </div>
            <div v-html="record.traveller_name"></div>
          </aCol>
        </aRow>
      </div>
    </div>
  </aTable>
</template>

<script>
import formatThings from "@/mixins/general/formatThings";
import contractMixins from "@/components/contracts/mixins/contractMixins";
import generateInvoicesCalcsAndRules from "@/components/finances/mixins/generateInvoicesCalcsAndRules";

export default {
  name: "InvoicePaxesTables",
  props: {
    contractList: Array,
    loading: Boolean,
  },
  mixins: [formatThings, contractMixins, generateInvoicesCalcsAndRules],
  data() {
    return {
      columns: [
        {
          title: "",
          scopedSlots: { customRender: "expandItem" },
          width: 40,
        },
        {
          title: "",
          scopedSlots: { customRender: "product_type" },
          width: 40,
        },
        {
          title: "Venda",
          scopedSlots: { customRender: "sale_id" },
          width: 70,
        },
        {
          title: "Loc.",
          dataIndex: "locator",
          key: "locator",
          width: 90,
        },
        {
          title: "Produto",
          scopedSlots: { customRender: "product" },
          width: 180,
        },
        {
          title: "Valor",
          key: "ticket_value",
          width: 120,
          scopedSlots: { customRender: "ticket_value" },
        },
        {
          title: "Tx. Embarque",
          key: "ticket_tax_value",
          width: 120,
          scopedSlots: { customRender: "ticket_tax_value" },
        },
        {
          title: "A receber",
          key: "incomings",
          width: 120,
          scopedSlots: { customRender: "incomings" },
        },
        {
          title: "A pagar",
          key: "to_pay",
          width: 120,
          scopedSlots: { customRender: "to_pay" },
        },

        {
          title: "Taxa extra",
          width: 120,
          scopedSlots: { customRender: "extra_fee_value" },
        },
        {
          title: "Saldo",
          key: "balance",
          width: 120,
          scopedSlots: { customRender: "balance" },
        },
      ],
    };
  },
  methods: {
    frontendFilters(contractList) {
      return contractList.filter((contract) => {
        return contract.invoice_id != 0;
      });
    },
  },
};
</script>
