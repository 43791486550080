<template>
  <main>
    <aRow :gutter="15">
      <aCol v-for="(panel, i) in valuePanelsArr" :key="i" :span="6">
        <div class="white-box card">
          <aRow type="flex" :gutter="20">
            <aCol flex="32px">
              <img :src="panel.img" :width="panel.width" alt="img" />
            </aCol>
            <aCol flex="auto" style="flex: 1 1">
              <div class="total">{{ panel.total }}</div>
              <div class="name">{{ panel.name }}</div>
              <div class="extra">{{ panel.extra }}</div>
            </aCol>
          </aRow>
        </div>
      </aCol>
    </aRow>

    <section class="transations-section" :class="isFullScreen ? 'full' : ''">
      <div class="transaction-filters">
        <aRow :gutter="10">
          <aCol :span="6">
            <div class="travel-input relative">
              <label for class="filled">Período</label>
              <a-range-picker
                v-model="bankTransaction.filters.period.selected"
                :format="['DD/MM/YYYY']"
                :value-format="'YYYY-MM-DD'"
                :disabled="bankTransaction.loading"
                @change="getBankTransactions()"
                style="background: transparent !important"
              />
            </div>
          </aCol>

          <aCol :span="5">
            <div class="travel-input relative">
              <label for class="filled">Fornecedores</label>
              <aSelect
                class="travel-input"
                placeholder="Selecione o fornecedor"
                v-model="bankTransaction.filters.productSupplier"
                optionFilterProp="txt"
                show-search
                allow-clear
                style="width: 100%"
                :disabled="bankTransaction.loading"
                @change="getBankTransactions()"
              >
                <aSelectOption
                  v-for="(item, index) of productSupplier.list"
                  :key="index"
                  :value="item.id"
                  :txt="item.trading_name"
                >
                  {{ item.id }} -
                  {{ item.trading_name.toUpperCase() }}
                </aSelectOption>
              </aSelect>
            </div>
          </aCol>

          <aCol :span="4">
            <div class="travel-input relative">
              <label for class="filled">Tipo</label>
              <aSelect
                class="travel-input"
                placeholder="Selecione"
                v-model="bankTransaction.filters.paymentMethods.selected"
                allow-clear
                style="width: 100%"
                :disabled="bankTransaction.loading"
                @change="onSelectType"
              >
                <a-select-option
                  v-for="(item, index) of bankTransaction.filters.paymentMethods
                    .list"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </aSelect>
            </div>
          </aCol>

          <aCol :span="4">
            <div class="travel-input relative">
              <label for class="filled">Documento</label>
              <a-input
                placeholder="Documento + Enter"
                v-model="bankTransaction.filters.documentId"
                :disabled="bankTransaction.loading"
                @pressEnter="getBankTransactions()"
              />
            </div>
          </aCol>

          <aCol :span="4">
            <div class="field checkboxes">
              <a-checkbox-group
                style="width: 100%"
                v-model="bankTransaction.filters.types.selected"
                :disabled="bankTransaction.loading"
                @change="getBankTransactions()"
              >
                <a-checkbox
                  v-for="(item, index) of bankTransaction.filters.types.list"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-checkbox>
              </a-checkbox-group>
            </div>
          </aCol>
        </aRow>

        <a-icon
          v-if="!isFullScreen"
          class="fullscreen"
          type="fullscreen"
          @click="isFullScreen = true"
        />
        <a-icon
          v-if="isFullScreen"
          class="fullscreen"
          type="fullscreen-exit"
          @click="isFullScreen = false"
        />

        <a-icon
          v-if="!generatePDFLoading"
          class="pdf"
          type="file-pdf"
          @click="onClickGeneratePdf"
        />
        <a-icon class="pdf" v-if="generatePDFLoading" type="loading" />
      </div>

      <div id="pdf-area">
        <div class="pdf-header" style="display: none">
          <table style="width: 100%; padding: 10px">
            <tr>
              <th style="font-size: 0">.</th>
            </tr>
            <tr>
              <td
                style="
                  border: 0;
                  color: #333;
                  padding-bottom: 50px;
                  font-size: 12px;
                  color: #333;
                "
              >
                <h1 style="font-size: 16px; font-weight: 600">
                  EXTRATO BANCÁRIO - {{ bankAccount.company_name }}
                </h1>

                <div v-for="(panel, i) in valuePanelsArr" :key="i" :span="6">
                  {{ panel.name }}:
                  <span :style="`color: ${panel.color}`">
                    <b>{{ panel.total }} </b></span
                  >
                  <div class="extra">{{ panel.extra }}</div>
                </div>
              </td>
              <td
                style="
                  text-align: right;
                  font-size: 10px;
                  color: #333;
                  border: 0;
                  padding-bottom: 50px;
                "
              >
                <div>
                  <b>Impresso por:</b> {{ $store.state.userData.first_name }}
                  {{ $store.state.userData.last_name }}
                </div>
                <div><b>Data:</b> {{ todayDate }}</div>
              </td>
            </tr>
          </table>
        </div>

        <aTable
          class="travel-table transactions-table"
          :columns="bankTransactionsTableColumns"
          :data-source="bankTransaction.list"
          :loading="bankTransaction.loading"
          :pagination="false"
          :scroll="{ x: 1000 }"
          :expandIconAsCell="false"
          :expandIconColumnIndex="7"
          @change="bankTransactionTableChange"
        >
          <div slot="product_supplier" slot-scope="record">
            <div v-if="record.product_supplier" class="dotted-phrase upper">
              <aTooltip :title="record.product_supplier.company_name">
                {{ record.product_supplier.id }} -
                {{ record.product_supplier.trading_name }}
              </aTooltip>
            </div>
            <div v-else>---</div>
          </div>

          <div slot="value" slot-scope="record">
            <div v-if="record.type === 'credit'" style="color: #00bd9b">
              {{ record.value | formatPricePtBr }}
            </div>
            <div v-if="record.type === 'debit'" style="color: red">
              {{ record.value | formatPricePtBr }}
            </div>
          </div>

          <div slot="type" slot-scope="record">
            <div v-if="record.type === 'credit'" style="color: #00bd9b">C</div>
            <div v-if="record.type === 'debit'" style="color: red">D</div>
          </div>

          <div slot="payment_method" slot-scope="record">
            <aTooltip :title="record.payment_method">
              <div class="truncate">{{ record.payment_method }}</div>
            </aTooltip>
          </div>

          <div slot="date" slot-scope="record">
            <aTooltip>
              <template #title>
                {{ record.created | formatMultiDateTime }}
              </template>
              {{ record.date | formatDate }}
            </aTooltip>
          </div>

          <div slot="description" slot-scope="record">
            <aTooltip :title="record.description">
              <div class="truncate">{{ record.description }}</div>
            </aTooltip>
          </div>

          <div slot="document_id" slot-scope="record">
            <template v-if="record.document_id">
              {{ record.document_id }}
            </template>
            <template v-if="record.event_id != 0">
              <a
                @click="$router.push(`/events/calendar?oid=${record.event_id}`)"
                >{{ record.event_id }}</a
              >
            </template>
          </div>

          <div slot="expandedRowRender" slot-scope="record">
            <div style="padding: 20px 0 10px">
              <aRow class="mb-10" type="flex" justify="space-between">
                <aCol>
                  <span class="mt-5">
                    <b>TRANSAÇÃO ID {{ record.id }}</b>
                  </span>
                  <span class="mt-5" v-if="record.contract_id != 0">
                    <aDivider type="vertical" /> <b>Contrato:</b>
                    <a
                      @click="
                        $router.push(`/contracts/edit/${record.contract_id}`)
                      "
                    >
                      {{ record.contract_id }}
                    </a>
                  </span>
                  <span class="mt-5" v-if="record.sale_id != 0">
                    <aDivider type="vertical" /> <b>Venda:</b>
                    <a
                      @click="$router.push(`/sales/list?id=${record.sale_id}`)"
                      >{{ record.sale_id }}</a
                    >
                  </span>
                  <span class="mt-5" v-if="record.event_id != 0">
                    <aDivider type="vertical" /> <b>Evento:</b>
                    <a
                      @click="
                        $router.push(`/events/calendar?oid=${record.event_id}`)
                      "
                      >{{ record.event_id }}</a
                    >
                  </span>
                  <span class="mt-5" v-if="record.invoice_id != 0">
                    <aDivider type="vertical" /> <b>Fatura:</b>
                    {{ record.invoice_id }}
                  </span>
                </aCol>
                <aCol>
                  <span class="mt-5">
                    <b>Data da transação:</b>
                    {{ record.created | formatMultiDateTime }}
                    <aDivider type="vertical" />
                  </span>

                  <aTooltip>
                    <template slot="title">
                      {{ record.user.first_name }} {{ record.user.last_name }}
                    </template>
                    <span id="avatar">
                      <a-avatar
                        v-if="record.user.avatar"
                        :src="record.user.avatar"
                        :size="30"
                      />
                      <a-avatar
                        v-else
                        class="upper"
                        style="color: #f56a00; background-color: #fde3cf"
                      >
                        {{ record.user.first_name.substring(0, 1)
                        }}{{ record.user.last_name.substring(0, 1) }}
                      </a-avatar>
                    </span>
                  </aTooltip>
                </aCol>
              </aRow>

              <div v-if="record.invoice_id != 0">
                <a-divider class="mt-0 mb-0" orientation="left">
                  Paxes da fatura
                  {{ record.invoice_id != 0 ? "ID  " + record.invoice_id : "" }}
                </a-divider>

                <BankTransactionsPaxesTable
                  :invoiceId="
                    record.invoice_id == 0 ? '9889898989898' : record.invoice_id
                  "
                />
              </div>
            </div>
          </div>
        </aTable>

        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swadp"
          rel="stylesheet"
        />

        <v-style data-v-60a9aadb="" class="none">
          #pdf-area { line-height: 0; font-family: "Poppins", Arial; } p {
          margin-top: 0; margin-bottom: 1em; } .ant-table-column-sorter {
          display: none } table { border-collapse: collapse; width: 100%;
          max-width: 100% } table { text-indent: initial; border-spacing: 2px; }
          .ql-align-center { text-align: center; } table td { color: #666;
          font-size: 10px; line-height: 14px; padding: 2px 5px; border-top: 1px
          solid #eee } table th { text-transform: uppercase; padding: 3px 5px;
          font-size: 8px; text-align: left; color: #333 } b, strong {
          font-weight: 600 !important; } .ql-align-justify { text-align:
          justify; } .pdf-header { display: block !important}
        </v-style>
      </div>

      <div class="a-center mt-10">
        <aPagination
          size="small"
          show-size-changer
          :default-current="bankTransaction.pagination.page"
          :page-size.sync="bankTransaction.pagination.perPage"
          :page-size-options="pageSizeOptions"
          :total="bankTransaction.pagination.total"
          @change="changeBankTransactionPage"
          @showSizeChange="bankTransactionChangePageSize"
        >
          <template slot="buildOptionText" slot-scope="props">
            <span v-if="props.value === '99999'">Todos</span>
            <span v-else>{{ props.value }} / página</span>
          </template>
        </aPagination>
      </div>
    </section>
  </main>
</template>

<script>
import axios from "axios";
import { format } from "date-fns";
import formatThings from "@/mixins/general/formatThings";

import productSupplierMixins from "@/mixins/product-suppliers/productSupplierMixins.js";
import bankTransactionsMixins from "../mixins/bankTransactionsMixins";
import BankTransactionsPaxesTable from "./BankTransactionsPaxesTable.vue";

export default {
  name: "BankContentSection",
  props: {
    bankAccount: Object,
  },
  components: { BankTransactionsPaxesTable },
  mixins: [bankTransactionsMixins, productSupplierMixins, formatThings],
  data() {
    return {
      isFullScreen: false,
      generatePDFLoading: false,
      pageSizeOptions: ["10", "20", "50", "100", "200", "500", "1000", "99999"],
      todayDate: format(new Date(), "dd/MM/yyyy HH:mm"),
    };
  },
  created() {
    this.$on("updateTransactionsList", this.handleEvent);
  },
  mounted() {
    let date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth(),
      firstDay = new Date(y, m, 1),
      lastDay = new Date(y, m + 1, 0);

    firstDay = format(firstDay, "yyyy-MM-dd");
    lastDay = format(lastDay, "yyyy-MM-dd");

    this.bankTransaction.filters.period.selected = [
      `${firstDay}`,
      `${lastDay}`,
    ];

    this.bankTransaction.filters.bankId = this.bankAccount.id;
    this.getBankTransactions();

    this.productSupplier.pagination.perPage = 9999;
    this.getProductSuppliers();
  },
  methods: {
    handleEvent() {
      this.getBankTransactions();
    },
    onSelectType(type) {
      this.bankTransaction.filters.paymentMethods.selected = type ?? [];
      this.getBankTransactions();
    },
    onClickGeneratePdf() {
      const documentsWrapper = document.getElementById("pdf-area");
      const outerHTMLContent = documentsWrapper.outerHTML;

      this.generatePDFLoading = true;

      axios
        .post(
          `https://integrations.haya.net.br/v1/integrations/generate-pdf`,
          {
            html: btoa(
              unescape(
                encodeURIComponent(
                  outerHTMLContent
                    .replace("v-style", "style")
                    .replace("v-style", "style")
                )
              )
            ),
          },
          {
            responseType: "arraybuffer",
          }
        )
        .then(({ data }) => {
          const blob = new Blob([data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);

          // Specify the filename for the download
          link.download = `Extrato bancário - ${this.bankAccount.company_name}.pdf`;

          // Append the link to the document
          document.body.appendChild(link);

          // Trigger the click event to start the download
          link.dispatchEvent(new MouseEvent("click"));

          // Remove the link from the document
          document.body.removeChild(link);

          this.generatePDFLoading = false;
        });
    },
  },
};
</script>

<style lang="sass">
.transations-section.full
  position: fixed
  left: 0
  right: 0
  top: -20px
  margin: 0
  z-index: 999
  bottom: 0
  border-radius: 0 !important
  padding: 20px
.transactions-table
  td
    font-size: 11px !important
.transaction-filters
  .ant-input, .ant-select-selection--single
    background: transparent !important
    font-size: 12px
  .field.checkboxes
    .ant-checkbox-wrapper
      text-transform: none
      font-size: 10px !important
      font-weight: 400
      margin: 0 !important
      padding: 4px 0px 0px 0
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
</style>

<style lang="sass" scoped>
.transaction-filters
  background: #F5F7FA
  padding: 20px 5px 10px
  border-radius: 8px 8px 0 0
  position: relative
  .pdf
    position: absolute
    right: 10px
    top: 10px
    font-size: 12px
    color: #999
  .fullscreen
    position: absolute
    right: 30px
    top: 10px
    font-size: 12px
    color: #999
  .label
    position: absolute
    color: #434a53 !important
    top: -10px
    z-index: 1
  label, .label
    color: #333
    font-size: 8px  !important
    font-weight: 600
    text-transform: uppercase
.transations-section
  background: #fff
  border-radius: 8px
  margin-top: 20px
  min-height: 400px
.white-box
  background: #fff
  padding: 15px
  border-radius: 8px
.card
  height: 81px
  font-size: 10px
  line-height: 1.2
  .total
    font-size: 20px
    font-weight: 600
</style>
