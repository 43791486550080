<template>
  <InvoicePaxesTable
    :contractList="contractList"
    :loading="loadingContractList"
  />
</template>

<script>
import contractMixins from "@/components/contracts/mixins/contractMixins";
import InvoicePaxesTable from "@/components/finances/sections/InvoicePaxesTable.vue";

export default {
  name: "BankTransactionsPaxesTable",
  props: {
    invoiceId: String,
  },
  components: { InvoicePaxesTable },
  mixins: [contractMixins],
  data() {
    return {
      contractList: [],
      loadingContractList: false,
    };
  },

  mounted() {
    this.loadingContractList = true;
    this.$http
      .get(
        `/contract-meta/list?page=1&per_page=100&order=desc&order-by=id&info_name=invoice_id&info_value=${this.invoiceId}`
      )
      .then(({ data }) => {
        this.contractList = data.data.map((item) => {
          return item.contract;
        });
      })
      .finally(() => (this.loadingContractList = false));
  },
};
</script>
